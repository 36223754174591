@mixin button-theme($borderColor:$danger) {
  font-size: 1.1rem;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  margin-top: -1px;
  letter-spacing: normal;
  text-align: center;
  color: rgb(255, 255, 255);
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid $borderColor;
  border-radius: 100px;

  &.btn-xs {
    @include button-size(
      $padding-y: 20px,
      $padding-x: 60px,
      $font-size: 1.1rem,
      $line-height: 16px,
      $border-radius: 100px
    );
  }
  &.btn-sm {
    @include button-size(
      $padding-y: 10px,
      $padding-x: 75px,
      $font-size: 1.1rem,
      $line-height: 36px,
      $border-radius: 100px
    );
  }
  &.btn-lg {
    @include button-size(
      $padding-y: 10px,
      $padding-x: 90px,
      $font-size: 1.1rem,
      $line-height: 36px,
      $border-radius: 100px
    );
  }
  &.btn-xl {
    @include button-size(
      $padding-y: 10px,
      $padding-x: 60px,
      $font-size: 1.1rem,
      $line-height: 36px,
      $border-radius: 100px
    );
  }
  &.btn-block {
    @include button-size(
      $padding-y: auto,
      $padding-x: auto,
      $font-size: 1.1rem,
      $line-height: 45px,
      $border-radius: 100px
    );
  }
  &.unlock {
    @include button-size(
      $padding-y: auto,
      $padding-x: auto,
      $font-size: 1.1rem,
      $line-height: 1.5,
      $border-radius: 24px
    );
  }
}
