@import "../../../styles/shared.scss";
.modalV2 {
  .register-form {
    .custom-control {
      &.fill-checkbox {
        --color: #1a46e5;

        .fill-control-input {
          display: none;
          &:checked ~ .fill-control-indicator {
            background-color: $orange;
            border-color: $orange;
            background-size: 80%;
          }
        }
        .fill-control-indicator {
          background-image: url("../../../assets/images/checked.png");
          border-radius: 3px;
          display: inline-block;
          position: absolute;
          top: 4px;
          left: 0;
          width: 20px;
          height: 20px;
          border: 1px solid $light-violet;
          transition: 0.3s;
          background: transperent;
          background-size: 0%;
          padding: 3px;
          background-position: center;
          background-repeat: no-repeat;
        }
        .fill-control-description {
          font-size: 12px;
          display: flex;
          align-items: center;
          line-height: 28px;
          padding-left: 10px;

          span.a {
            color: $secondary;
          }
        }
      }
    }
    .btn-outline-secondary {
      @include button-theme();
      border: 2px solid $lightish-blue;
      color: $lightish-blue;
      text-shadow: none;
      font-weight: 500;
      font-size: 15px;
      background:#fff;
      &.social-login {
        @include button-size(
          $padding-y: auto,
          $padding-x: auto,
          $font-size: 16px,
          $line-height: 25px,
          $border-radius: 100px
        );
        .social-icon {
          &.facebook {
            img {
              width: 0.7rem;
            }
          }
          &.google {
            img {
              width: 1.3rem;
            }
          }
        }
      }
      &:hover {
        background: lighten($secondary, $amount: 45);
      }
    }
    .without-lable {
      .field-icon.eye-open {
        background-image: url("../../../assets/images/svg/eye-default.svg");
      }
      .field-icon.eye-close {
        background-image: url("../../../assets/images/svg/eye-close.svg");
      }
      .field-icon {
        float: right;
        margin-left: -25px;
        margin-top: -25px;
        position: relative;
        z-index: 99999999999;
        width: 25px;
        height: 25px;
      }
    }
  }
  .iphone-SE-top {
    &.mt {
      margin-top: 2rem !important;
    }
  }
}
