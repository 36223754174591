@import "../../styles/shared.scss";

.select2 .dropdown {
  position: relative;
  width: 100%;

  .selection-heading {
    padding: 20px 15px 15px 15px;
    margin-bottom: 0px;
    font-size: 15px;
    font-weight: 500;
    box-shadow: inset 0 -1px 0 0 $pale-blue;
  }

  .down-arrow-dropdown {
    position: absolute;
    right: 15px;
    top: 5px;
    z-index: 3;
    transition: all 0.5s ease-in-out;

    &.openArrow {
      transform: rotate(180deg);
    }
  }

  &--open .dropdown__list {
    display: block;
  }
}

.select2 .dropdown__list {
  position: absolute;
  z-index: 2;
  display: none;
  top: -15px;
  left: 0;
  width: 100%;
  overflow: hidden;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  ul {
    max-height: 200px;
    overflow: auto;
    margin-bottom: 0px;
  }
  li {
    padding: 10px 15px;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    &:hover {
      background-color: rgba($pale-blue, 0.3);
    }
  }
}

.select2 input {
  &:focus {
    outline: none;
    caret-color: transparent !important;
  }
  cursor: pointer;
  caret-color: transparent !important;
}
