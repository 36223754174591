.card {
  &.card-arcadexMobi {
    @include card-arcadexMobi();
    @include media-breakpoint-down(sm) {
      @include card-arcadexMobi(
        $CardWidth: 200px,
        $cardHeight: 176px,
        $imgHeight: 176px,
        $card-inner-border-radius: 16px,
        $card-border-radius: 16px
      );
    }
  }
  &.popular-arcadex {
    margin-bottom: 20px;
    @include popular-arcadex();
    &.dark {
      @include box-shadow(0px, 3px, 10px, darken($info, 10%));
    }
    .card-body {
      padding: 0rem 2rem;
      @include media-breakpoint-down(sm) {
        padding: 0rem 1.2rem;
      }
    }
    figure {
      margin-bottom: 7px;
      img {
        margin-left: -20px;
      }
      figcaption {
        margin-top: 2px;
        color: $secondary;
      }
      margin-top: -50px;
    }
    @include media-breakpoint-down(sm) {
      @include popular-arcadex(
        $CardWidth: 200px,
        $cardHeight: 320px,
        $imgHeight: 176px,
        $card-inner-border-radius: 0px,
        $card-border-radius: 16px
      );
      .card-header {
        font-size: 12px;
      }
      .card-body {
        h4 {
          font-size: 16px;
          font-weight: bold;
        }
        figure {
          margin-top: -30px;
          img {
            margin-left: -10px;
          }
          figcaption {
            font-size: 12px;
          }
        }
      }
    }
  }
  &.quick-play {
    @include quick-play();
    @include media-breakpoint-down(sm) {
      @include quick-play(
        $CardWidth: 200px,
        $cardHeight: 176px,
        $imgHeight: 176px,
        $card-inner-border-radius: 16px,
        $card-border-radius: 16px
      );
    }
    small {
      font-size: 12px;
      color: $bluish-grey;
      font-weight: 500;
    }
  }
  &.popular-host {
    @include popular-host();
    figure {
      img {
        position: relative;
      }
      .status {
        position: absolute;
        top: 15px;
        right: 25px;
        width: 18px;
        height: 18px;
        background-image: radial-gradient(circle at 50% 31%, #73ffb3, #50de80);
        box-shadow: inset 0 -2px 0 0 rgba(0, 0, 0, 0.05);
        border-radius: 50%;
        border: 2px solid white;
        @include media-breakpoint-down(sm) {
          top: 5px;
          right: 13px;
        }
      }
      figcaption {
        font-size: 16px;
        line-height: 1.38;
        font-weight: 500;
        margin-bottom: 0px;
        @include media-breakpoint-down(sm) {
          font-size: 12px;
        }
        width: 120px;
        overflow: hidden;
      }
    }
    @include media-breakpoint-down(sm) {
      figcaption {
        font-size: 12px;
      }
    }
  }
  &.free-to-play-game {
    @include free-to-play-game();

    @include media-breakpoint-down(sm) {
      @include free-to-play-game(
        $CardWidth: 200px,
        $cardHeight: 180px,
        $imgHeight: 180px
      );
    }
  }
  &.prize-pool {
    margin-bottom: 20px;
    @include prize-pool();
    .card-body {
      figure {
        img {
          max-width: 120px;
          @include media-breakpoint-down(sm) {
            max-width: 90px;
          }
        }
        figcaption {
          margin-top: 10px;
          margin-bottom: 10px;
        }
        margin-top: -105px;
      }
    }
    @include media-breakpoint-down(sm) {
      @include prize-pool(
        $CardWidth: 200px,
        $cardHeight: 320px,
        $imgHeight: 170px
      );
    }
  }
  &.enter-raffles {
    @include enter-raffles();

    .card-body {
      padding: 0px;
      position: relative;
      .card-info {
        position: absolute;
        bottom: 0px;
        background: rgba($bluish-grey, 0.9);
        min-height: 150px;
        padding: 15px;
        width: 100%;
        figure {
          figcaption {
            min-height: 50px;
          }
          margin-top: -50px;
        }
        .btn-pink, .btn-secondary, .btn-mtnBlack {
          &.btn-block {
            line-height: 20px;
            margin-top: 5px;
            margin-bottom: 7px;
            font-size: 14px;
          }
        }
        &.active-nickx, &.active-arcadex {
          background: rgba($secondary, 0.9);
        }

        @include media-breakpoint-down(sm) {
          .profile-pic-56 {
            width: 56px;
            height: 56px;
          }
        }
      }
      &:before {
        content: "";
        position: absolute;
        top: 0px;
        left: 0;
        right: 0;
        margin: 0 auto;
        height: 25px;
        width: 50px;
        background: #ffffff;
        border-bottom-left-radius: 40px;
        border-bottom-right-radius: 40px;
      }
      &:after {
        content: "";
        position: absolute;
        bottom: 0px;
        left: 0;
        right: 0;
        margin: 0 auto;
        height: 25px;
        width: 50px;
        background: #ffffff;
        border-top-left-radius: 40px;
        border-top-right-radius: 40px;
      }
    }
    .card-footer {
      background: transparent;
      margin-top: -25px;
      .raffle-timer {
        img {
          max-width: 24px;
          max-height: 24px;
        }
        position: relative;
        z-index: 1;
      }
    }
  }
  &.card-my-team {
    @include card-my-team();
    @include box-shadow(0px, 3px, 10px, darken($info, 10%));

    img {
      width: 100%;
    }
  }
}
