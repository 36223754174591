// Font Families
$font-primary: 'worksansregular', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-primary-light: 'worksanslight', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-primary-medium: 'worksansmedium', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-primary-bold: 'worksansbold', 'Helvetica Neue', Helvetica, Arial, sans-serif;

$font-secondary: 'brightersansegular', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-secondary-light: 'brightersanslight', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-secondary-medium: 'brightersansmedium', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-secondary-bold: 'brightersansbold', 'Helvetica Neue', Helvetica, Arial, sans-serif;

// Color Variables
$white: #ffffff;
$black: #111111;
$pureBlack: #000000;
$dark-grey: #292929;
$brandMainColor: #ffcb03;
$body-bg-color: #181818;

.package-select-modal {
	z-index: 9000;
	.dimmed-background {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 8000;
		background-color: rgba($white, 0.92);
	}
	.modal-content-container {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 10000;
		display: flex;
		align-items: center;
		justify-content: center;
		pointer-events: none;
		.modal-contentx {
			max-width: 500px;
			max-height: calc(100% - 40px);
			overflow: auto;
			background: $body-bg-color;
			position: relative;
			box-shadow: 0 0 50px rgba($pureBlack, 0.5);
			pointer-events: all;
			.close-btn {
				width: 34px;
				height: 34px;
				padding: 6px;
				border-radius: 50%;
				background-color: #333;
				position: absolute;
				top: 20px;
				right: 20px;
				z-index: 0;
				cursor: pointer;
				img {
					width: 100%;
					display: block;
				}
				&:active {
					opacity: 0.25;
					transition: opacity 0.5s ease;
				}
			}
			.hero-image {
				width: 100%;
				display: block;
			}
			.form-container {
				padding: 20px;
				.btn {
					margin: 20px 0 0;
				}
				.already-have-account-btn {
					display: block;
					width: 280px;
					margin: 18px auto 0;
					text-align: center;
					.underlined-text {
						line-height: 18px;
						display: inline-block;
						border-bottom: 1px solid rgba($white, 0.75);
					}
					&:active {
						opacity: 0.4;
						transition: opacity 0.2s ease;
					}
				}
				h1 {
					margin: -90px 0 30px;
					text-align: center;
					color: $white;
					font-size: 48px;
					line-height: 42px;
				}
				.package-btn {
					width: 100%;
					margin-bottom: 15px;
					padding: 16px 20px;
					display: flex;
					align-items: center;
					justify-content: space-between;
					background: rgba(255, 255, 255, 0.05);
					border-radius: 8px;
					text-align: left;
					font-family: $font-secondary-bold;
					transition: all 0.5s ease;
					cursor: pointer;
					.checkbox {
						width: 24px;
						height: 24px;
						margin-right: 20px;
						border-radius: 50%;
						border: 2px solid $white;
						flex-shrink: 0;
					}
					.interval {
						flex-shrink: 0;
						flex: 1;
						margin-right: 20px;
						color: $brandMainColor;
						font-size: 20px;
						line-height: 20px;
						span {
							padding-top: 2px;
							display: block;
							font-family: $font-primary;
							color: $white;
							font-size: 15px;
							line-height: 15px;
						}
					}
					.price {
						width: 55px;
						flex-shrink: 0;
						color: $brandMainColor;
						font-size: 20px;
						line-height: 20px;
						text-align: center;
						.rand-value {
							margin-bottom: 2px;
							display: flex;
							justify-content: center;
						}
						span {
							display: block;
							font-family: $font-primary-medium;
							color: $white;
							font-size: 10px;
							line-height: 10px;
							&.rand-symbol {
								margin-right: 2px;
								display: inline;
								line-height: 15px;
							}
						}
					}
					&.selected {
						background: $brandMainColor;
						.checkbox {
							border-color: $body-bg-color;
							background-color: $body-bg-color;
							display: flex;
							align-items: center;
							justify-content: center;
							&::after {
								content: '';
								margin-top: -2px;
								display: inline-block;
								width: 6px;
								height: 12px;
								transform: rotate(45deg);
								border-bottom: 2px solid $brandMainColor;
								border-right: 2px solid $brandMainColor;
							}
						}
						.interval {
							color: $body-bg-color;
							span {
								color: $body-bg-color;
							}
						}
						.price {
							color: $body-bg-color;
							span {
								color: $body-bg-color;
							}
						}
					}
					&:active {
						opacity: 0.5;
						transition: all 0.2s ease;
					}
				}
			}
		}
	}
}
