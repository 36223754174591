@import "../../styles/shared.scss";

%commanCardCss {
  background-image: linear-gradient(to top, #ffffff, #f8faff);
  .view-all {
    @extend %viewAllSecondary;
  }
  .swiper-slide {
    width: auto;
  }
}

%viewAllSecondary {
  font-weight: 500;
  font-size: 16px;
  color: $secondary;
  @include media-breakpoint-down(md) {
    font-size: 12px;
    font-weight: bold;
  }
}

.downloads-container {
  margin-bottom: 50px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  @include media-breakpoint-down(sm) {
    margin-bottom: 30px;
  }
  .download-btn {
    display: block;
    color: $white;
    background: $mtnThemeMainColor url(../../assets/images/svg/download-icon.svg) no-repeat right 20px center;
    background-size: 16px 16px;
    outline: none;
    text-align: left;
    border-radius: 6px;
    padding: 16px 60px 16px 20px;
    font-size: 14px;
    line-height: 14px;
    font-weight: 600;
    text-transform: uppercase;
    min-width: 160px;
    margin: 0 10px 0 0;
    transition: all 0.5s ease;
    border: none !important;
    cursor: pointer;
    z-index: 1000;
    @include media-breakpoint-down(sm) {
      font-size: 12px;
      padding: 1rem 50px 1rem 15px;
      min-width: 140px;
    }
    @include media-breakpoint-down(xs) {
      width: 100%;
      margin: 0 0 10px 0;
    }
    &:hover {
      background-color: lighten($mtnThemeMainColor, 15%);
    }
    &:active {
      transition: all 0.2s ease;
      background-color: darken($mtnThemeMainColor, 15%);
    }
  }
}

.news {
  &.event-section {
    margin-top: -150px;
  }
  &.detail-section {
    margin-top: -280px;
    .news-avatar {
      width: 120px;
      height: 120px;
      overflow: hidden;
      background: #ccc;
      border: 3px solid #fff;
      box-shadow: 0 2px 23px -8px rgba(60, 64, 85, 0.4);
      border-radius: 60px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
      }
    }

    .bannerImg-section {
      width: 100%;
      height: 390px;
      border-radius: 16px;
      background: #ccc;
      overflow: hidden;
      box-shadow: 0 9px 40px -8px rgba(0, 0, 0, 0.2);

      img {
        object-fit: cover;
        object-position: top;
        width: 100%;
        height: 100%;
      }
    }

    .media {
      .avatar {
        width: 88px;
        height: 88px;
        border-radius: 44px;
      }
      .media-body {
        li {
          img {
            width: 28px;
            height: 28px;
          }
        }
      }
    }
    .text-lightGray {
      color: $bluish-grey;
    }
  }
  @include media-breakpoint-down(lg) {
    &.detail-section {
      margin-top: -180px;
      .news-avatar {
        width: 80px;
        height: 80px;
      }
    }
  }
  @include media-breakpoint-down(md) {
    &.event-section {
      margin-top: -50px;
    }
    &.detail-section {
      margin-top: -150px;
      .news-avatar {
        width: 80px;
        height: 80px;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    &.detail-section {
      margin-top: -150px;
      .news-avatar {
        width: 80px;
        height: 80px;
      }
    }
  }
  @include media-breakpoint-down(xs) {
    &.detail-section {
      margin-top: -100px;
      .news-avatar {
        width: 80px;
        height: 80px;
      }
    }
  }
}
.news-wrapper {
  background-image: linear-gradient(to bottom, #ffffff, #f8faff);
}
.event-section {
  .form-row {
    margin-left: 0px;
    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #3c4055;
      opacity: 1; /* Firefox */
    }
    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #3c4055;
    }
    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #3c4055;
    }
  }
}
.about-mtn-treasure {
  @extend %commanCardCss;
  .tournament-accordian {
    .card {
      background: transparent;
      &.bb-1 {
        box-shadow: inset 0 -1px 0 0 $pale-blue;
      }
      .card-header {
        background: transparent;
        padding: 25px 0px;
        .arrow {
          transition: all 0.5s ease-in-out;
        }
        cursor: pointer;
      }
      .card-body {
        padding: 0px 0px 25px 0px;
        img {
          max-width: 65px;
        }
      }
    }
  }
  @include media-breakpoint-down(md) {
    p {
      br {
        display: none;
      }
    }
  }
}
.accordion {
  border: 0px solid rgba(0, 0, 0, 0.1);
}
.card-header {
  outline: none;
}
#root {
  outline: none !important;
}

// .news {
//   &
  // .help-support {
  //   .contents {
  //     position: absolute;
  //     width: 60%;
  //     height: 60%;
  //     h2 {
  //       left: -25px;
  //       position: relative;
  //     }
  //     h5 {
  //       left: -18px;
  //       position: relative;
  //     }
  //   }
  //   @include media-breakpoint-down(lg) {
  //     //margin-top: -80px;
  //     .contents {
  //       h2 {
  //         left: 35px;
  //         font-size: 32px;
  //       }
  //       h5 {
  //         left: 38px;
  //       }
  //     }
  //   }
  //   @include media-breakpoint-down(md) {
  //     background-color: $lightish-blue;
  //     &.nickX {
  //       background-color: $orange;
  //     }
  //     &.mtn {
  //       background-color: $mtnThemeMainColor;
  //     }
  //     margin-left: 0px;
  //     margin-top: 0px;
  //     svg {
  //       display: none;
  //     }
  //     .contents {
  //       position: relative;
  //       width: 100%;
  //       height: 100%;
  //       margin-left: 0px;
  //       padding: 20px 0px;
  //       h2 {
  //         font-size: 32px;
  //         left: -15px;
  //         position: relative;
  //       }
  //       h5 {
  //         left: -15px;
  //         position: relative;
  //       }
  //     }
  //     .mt-6 {
  //       margin-top: 2rem;
  //     }
  //     .mb-6 {
  //       margin-bottom: 2rem;
  //     }
  //   }
  //   @include media-breakpoint-down(sm) {
  //     .contents {
  //       h2 {
  //         position: relative;
  //       }
  //       h5 {
  //         position: relative;
  //       }
  //     }
  //   }
  // }
// }
.bg-light-gradient {
  background-image: linear-gradient(to top, #ffffff, #f8faff);
}

.no-premium .banner-wrapper .banner {
  margin-top: 112px;
}

.position-relative {
  position: relative !important;
}
