/*
=====Table of content======

B. banner

==========================
*/

//B. Banner
//Navigation menu sidebar for Responsive only
.left-sidebar-collapse {
	font-size: 1.2rem;
}

%sidebar {
	position: fixed;
	height: 100%;
	z-index: 1027;
	background: white;
	transition: all 0.3s;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
	top: 0;

	.top-right-profile-container {
		background-image: url("../assets/images/mtn-right-profile-bg.png");
		background-size: 100% 100%;
		color: white !important;
	}

	.dismiss {
		width: 35px;
		height: 35px;
		line-height: 35px;
		text-align: center;
		position: absolute;
		top: 20px;
		right: 10px;
		z-index: 10;
		cursor: pointer;
		-webkit-transition: all 0.3s;
		-o-transition: all 0.3s;
		transition: all 0.3s;
	}
	.sidebar-header {
		// background: #fff;
		// z-index: 1;
		position: relative;
		.navbar-brand {
			width: 80%;
		}
	}
	.sidebarLogo {
		width: 10rem;
		max-width: 150px;
	}
}

%leftSide {
	width: 80vw;
	max-width: 300px;
	left: -81vw;
	&.active {
		left: 0;
	}
}
%rightSide {
	width: 90vw;
	max-width: 380px;
	@include media-breakpoint-down(sm) {
		max-width: 320px;
	}
	right: -90vw;
	&.active {
		right: 0;
	}
}

.left-sidebar {
	@extend %sidebar;
	@extend %leftSide;

	.sidebar-header {
		padding: 20px;
	}
	ul {
		li {
			a,
			.a {
				padding: 10px;
				padding-left: 20px;
				font-size: 1.1em;
				display: block;
				color: $default;
				text-decoration: none;
				&:hover {
					&.nickx {
						color: $orange;
					}
					&.mtn {
						color: $mtnThemeMainColor;
					}
					color: #ff355d;
				}
			}
			&.active {
				a,
				a[aria-expanded="true"] {
					color: $orange;
				}
			}
			a[data-toggle="collapse"] {
				position: relative;
			}
		}
	}
	.nestingMenu {
		padding: 10px;
		padding-left: 20px;
		font-size: 1.1em;
		display: block;
		color: #3c4055;
		text-decoration: none;
		&.nickx,
		&.mtn {
			@include hover-focus {
				color: rgba($orange, 1);
			}
		}
		&.nickx {
			&.active {
				color: $orange;
				&::after {
					background-color: $orange;
				}
			}
		}
		&.mtn {
			&.active {
				color: $mtnThemeMainColor;
				&::after {
					background-color: $mtnThemeMainColor;
				}
			}
		}
	}
	.subMenuContainer {
		padding-left: 30px;
		height: 0;
		&.fadein,
		&.fadeout {
			visibility: hidden;
			-moz-transition: visibility 0.4s ease-in-out;
			-o-transition: visibility 0.4s ease-in-out;
			-webkit-transition: visibility 0.4s ease-in-out;
			transition: visibility 0.4s ease-in-out;
			-moz-transition: height 0.4s ease-in-out;
			-o-transition: height 0.4s ease-in-out;
			-webkit-transition: height 0.4s ease-in-out;
			transition: height 0.4s ease-in-out;
		}
		&.fadein {
			visibility: visible;
			height: auto;
		}
	}
}

.profile-slider {
	@extend %rightSide;
	@extend %sidebar;
	.profile-slider-container {
		&.landscape {
			@include media-breakpoint-down(sm) {
				height: auto !important;
			}
		}
		@media (max-width: 320px) {
			height: auto !important;
		}
	}

	.sidebar-header {
		// background-image: linear-gradient(to bottom, #ffffff, #f8faff);
		box-shadow: inset 0 -1px 0 0 rgba(224, 228, 232, 0.5);
		padding: 20px;
		&::before {
			content: "";
			// background-image: url("../assets/images/sidebar-shap-inner.png");
			background-repeat: no-repeat;
			background-size: 15%;
			position: absolute;
			top: 0px;
			background-position: left;
			left: 0;
			width: 230px;
			height: 100%;
			z-index: -1;
		}

		&::after {
			content: "";
			// background-image: url("../assets/images/sidebar-shap-inner.png");
			background-repeat: no-repeat;
			background-size: auto 100%;
			width: 355px;
			height: 200px;
			background-color: $secondary;
			background-repeat: no-repeat;
			position: absolute;
			top: -120px;
			left: -180px;
			transform: rotate(60deg);
			z-index: -2;
		}
		&.nickx {
			&::before {
				// background-image: url("../assets/images/sidebar-shap-inner-nick.png");
			}
			&::after {
				background-color: $orange;
				// background-image: url("../assets/images/sidebar-shap-inner-nick.png");
			}
		}
		&.mtn {
			// &::before {
			// 	background-image: url("../assets/images/sidebar-shap-inner-mtn.png");
			// }
			// &::after {
			// 	background-color: $mtnBlack;
			// 	background-image: url("../assets/images/sidebar-shap-inner-mtn.png");
			// }
		}
		figure {
			position: relative;
			.online {
				position: absolute;
				top: 10px;
				right: 115px;
				width: 16px;
				height: 16px;
				border: 2px solid #fff;
				background-image: radial-gradient(
					circle at 50% 31%,
					#73ffb3,
					#50de80
				);
				box-shadow: inset 0 -2px 0 0 rgba(0, 0, 0, 0.05);
				border-radius: 50%;
			}
		}
		@include media-breakpoint-down(sm) {
			.edit-buttons {
				font-size: 12px;
			}
		}
		.profile-name {
			color: #ffcb02;
			font-size: 12px;
		}
	}
	.side-links-wrapper {
		background-color: #f9faff;
		.side-links {
			padding: 10px 20px;
			font-size: 16px;
		}
		ul {
			.border-top {
				border-top: 1px solid $pale-blue;
			}
			margin-bottom: 0px;
		}
		.coin-counter {
			padding: 10px 20px;
			background-image: radial-gradient(
				circle at 50% 58%,
				$lightish-blue,
				$secondary
			);
			&.nickx {
				background-image: radial-gradient(
					circle at 50% 58%,
					#ff6d00,
					#ff6d00
				);
			}
			&.mtn {
				background-image: none;
				background-color: $mtnBlack;
			}
			h5 {
				font-size: 16px;
			}
			.token-img {
				max-width: 55px;
				width: 10vw;
			}
		}
	}
	.player-content {
		img {
			width: 8vw;
			max-width: 135px;
		}
	}
	@include media-breakpoint-down(lg) {
		.sidebar-header {
			background: transparent;
			box-shadow: none;
			.profile-pic {
				max-width: 90px;
			}
			&::before {
				background-size: 20%;
				top: -15px;
			}
			&::after {
				width: 350px;
				height: 200px;
			}
			figure {
				.online {
					right: 125px;
				}
			}
			.profile-pic-120 {
				width: 80px;
				height: 80px;
			}
		}
		.player-content {
			h2 {
				font-size: 16px;
			}
			h6 {
				font-size: 12px;
				&.text-secondary {
					color: $danger !important;
				}
			}
			img {
				width: 80px;
			}
		}
	}
	@include media-breakpoint-down(sm) {
		.player-content {
			img {
				width: 80px;
			}
		}
		figure {
			.online {
				right: 95px !important;
			}
		}
	}
}
.chat-list-slider {
	@extend %rightSide;
	@extend %sidebar;
	.sidebar-header {
		.message-header {
			padding: 30px 20px 10px;
		}
	}
	.message-list {
		padding: 15px 20px;
		.time-indication {
			color: #7b8997;
		}
		.mw-150 {
			max-width: 160px;
		}
	}
	.message-collapse {
		cursor: pointer;
	}
}
.chat-slider-mobile {
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	margin-top: 72px;
	width: 100%;
	.sidebar-header {
		.message-header {
			padding: 15px;
		}
	}
	.message-list {
		padding: 15px;
		.time-indication {
			color: #7b8997;
		}
		.mw-150 {
			max-width: 160px;
		}
	}
	.message-collapse {
		cursor: pointer;
	}
	.sidebar-header {
		.message-header {
			padding: 30px 20px;
			@include media-breakpoint-down(md) {
				padding: 20px 20px 10px 20px;
			}
		}
		.account-tab {
			padding: 0px 20px;
		}
	}
	.form-info {
		padding: 20px;
	}
}
.my-friend-slider {
	@extend %rightSide;
	@extend %sidebar;
	.sidebar-header {
		.message-header {
			padding: 30px 20px;
		}
	}
	.message-list {
		padding: 10px 20px;
		.mw-150 {
			max-width: 160px;
		}
		.more-icon {
			transform: rotate(90deg);
			cursor: pointer;
		}
		.dropdown-menu {
			text-align: center;
			border-radius: 13px;
			box-shadow: 0 6px 26px -8px rgba(60, 64, 85, 0.16);
			border: 1px solid rgba(0, 0, 0, 0.08);
			left: -15px;

			.dropdown-item {
				font-size: 16px;
				color: $secondary;
				font-weight: 500;
				&.active {
					background-color: #f8f9fa;
				}
				&:active {
					background-color: #f8f9fa;
				}
			}
			.border-line {
				box-shadow: inset 0 -1px 0 0 #e0e4e8;
				height: 1px;
			}
			.message-collapse {
				cursor: pointer;
			}
		}
	}
}
.accounts-slider {
	@extend %rightSide;
	@extend %sidebar;
	.sidebar-header {
		.message-header {
			padding: 30px 20px;
		}
		.account-tab {
			padding: 0px 20px;
		}
	}
	.form-info {
		padding: 20px;
	}
}
.chat-window-slider {
	@extend %rightSide;
	@extend %sidebar;
	.sidebar-header {
		.message-header {
			padding: 15px 20px;
		}
	}
	.chat-window {
		padding: 20px;

		height: 100%;
		.date-time {
			color: $bluish-grey;
		}
		.media {
			.media-body {
				margin-top: 12px;
				.message-wrapper {
					background: #ccc;
					padding: 15px 14px;
					&.message {
						border-radius: 8px;
						box-shadow: 0 2px 10px -3px rgba(69, 75, 84, 0.24);
						background-color: #ffffff;
					}
					&.replay {
						border-radius: 8px;
						background-color: rgba($secondary, 0.1);
					}
				}
			}
		}
		/* height: calc(100vh -400px); */
		overflow: auto;
		-webkit-overflow-scrolling: touch;
	}
}
.background-reverse {
	background-image: linear-gradient(to bottom, #f8faff, #fff);
}
.message-view-responsive {
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	margin-top: 72px;
	width: 100%;
	.sidebar-header {
		.message-header {
			padding: 15px 20px;
		}
		.media {
			img {
				width: 48px;
				height: 48px;
			}
		}
		// background: #fff;
	}
	.chat-window {
		// background-image: linear-gradient(to bottom, #f8faff, #fff);
		padding: 20px;
		height: 100%;
		.date-time {
			color: $bluish-grey;
		}
		.media {
			img {
				width: 48px;
				height: 48px;
			}
			.media-body {
				margin-top: 12px;
				.message-wrapper {
					background: #ccc;
					padding: 15px 14px;
					&.message {
						border-radius: 8px;
						box-shadow: 0 2px 10px -3px rgba(69, 75, 84, 0.24);
						background-color: #ffffff;
					}
					&.replay {
						border-radius: 8px;
						background-color: rgba($secondary, 0.1);
					}
				}
			}
		}
	}
}
.footer-text-view {
	width: 100%;
	border-top: 1px solid $pale-blue;
	padding: 0px 20px;
	form {
		padding: 10px 0px;
	}
	.without-lable {
		margin-bottom: 0px;
		.effect-1 {
			border-bottom: 0px;
		}
	}
	background: #fff;
}
.overlay {
	display: none;
	position: fixed;
	top: 0;
	width: 100vw;
	height: 100vh;
	background: rgba(0, 0, 0, 0.7);
	z-index: 1026;
	opacity: 0;
	transition: all 0.5s ease-in-out;
	&.active {
		display: block;
		opacity: 1;
	}
	touch-action: none;
}
.fixed-sidebar-scroll-landscap {
	&.active {
		height: 100%;
	}
}
.back-arrow {
	cursor: pointer;
	img {
		transform: rotate(180deg);
	}
}
.startChatBtnMinimum {
	min-width: 80px;
}
// .message-wrapper {
//   p {
//     // word-break: break-all;
//   }
// }
