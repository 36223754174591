@import "../../../styles/shared.scss";

.modalV2 {
  .create-avatar {
    .avatar {
      position: relative;
      .avatar-wrapper {
        position: relative;
        width: 200px;
        height: 200px;
        img {
          max-width: 200px;
          max-height: 200px;
          border-radius: 50%;
        }
        .upload-button {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          background: rgba(255, 255, 255, 0.85);
          padding-top: 5px;
          padding-bottom: 35px;
          width: 200px;
          margin: 0 auto;
          border-bottom-left-radius: 100px;
          border-bottom-right-radius: 100px;

          img {
            width: 30px;
            height: 30px;
            margin-bottom: 5px;
          }

          .choose-photo {
            font-size: 16px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.38;
            letter-spacing: normal;
            text-align: center;
            color: $secondary;
          }
        }
        .remove-avatar {
          position: absolute;
          bottom: 20px;
          right: 10px;
          background: #fff;
          box-shadow: 0 3px 16px -7px rgba(0, 0, 0, 0.2);
          border-radius: 50%;
          padding: 5px;
        }
      }
      .file {
        visibility: hidden;
        position: absolute;
      }
    }
  }
  .iphone-SE-top {
    &.mt {
      margin-top: 2rem !important;
    }
  }
}
