@import "./shared.scss";
@import "./bootstrap";
@import "button";
@import "form";
@import "cards";
@import "sidebar";
@import "navigation";
@import "footer";
@import "profile-pic";
@import "typography";
@import "./tab-bar.scss";
@import "datepicker";
@import url("https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap");

html,
body,
#root {
  height: 100%;
  width: 100%;
  font-size: 14px;
  line-height: 1.5;
  color: $default;
  font-family: $font-family-base;
  font-weight: normal;
}
.loader-mtn{
  margin: 50px;
  width: 60px;
  height: auto;
}
a,
a:hover,
a:focus,
.a,
.a:hover,
.a:focus {
  color: $default;
  text-decoration: none;
  cursor: pointer;
}
.bold {
  font-weight: bold;
}
.padder {
  padding: 8rem 0rem;
  @include media-breakpoint-down(sm) {
    padding: 6rem 0rem;
  }
}
.padder-main {
  padding: 5rem 0rem;
  @include media-breakpoint-down(lg) {
    padding: 7rem 0rem;
  }
  @include media-breakpoint-down(md) {
    padding: 3rem 0rem;
  }
}
.padder-top {
  padding-top: 6rem;
  @include media-breakpoint-down(lg) {
    padding-top: 3rem;
  }
}
.mb-5-half {
  margin-bottom: 2.5rem;
  @include media-breakpoint-down(sm) {
    margin-bottom: 1.5rem;
  }
}
.uppercase{
  text-transform: uppercase;
}
figure {
  margin-bottom: 0px;

  figcaption {
    margin-bottom: 0px;
  }
}
.fixed-bottom {
  z-index: 10 !important;
}
.mb-30 {
  margin-bottom: 30px;
  @include media-breakpoint-down(sm) {
    margin-bottom: 15px;
  }
}
.text-mtn-light-black{
  color: $mtn-light-black;
}
.text-gray{
  color: $gray;
}
.text-yellow{
  color: $yellow;
}
.bold {
  font-weight: bold;
}
.semi-bold {
  font-weight: 500;
}
.section-border-top {
  border-top: 1px solid rgba($pale-blue, 0.5);
}
.mx-15 {
  margin-left: 15px;
  margin-right: 15px;
}
.fs-12 {
  font-size: 12px;
}
.bb-1 {
  box-shadow: inset 0 -1px 0 0 $pale-blue;
}
.bt-1 {
  border-top: 1px solid $pale-blue;
}
.mt-72 {
  margin-top: 72px;
}

.bold {
  font-weight: bold;
}

.section-border-top {
  border-top: 1px solid rgba($pale-blue, 0.5);
}
.profile-pic-120 {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}
.profile-pic-80 {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}
.profile-pic-48 {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}
// .go-premium {
//   position: fixed;
//   top: 0;
//   width: 100%;
//   height: 20px;
//   z-index: 100000;
//   background-color: lighten($secondary, 20%);
// }

//added for banner in news,contact ,terms & condition ,privacy policy
.banner {
  &.news {
    // margin-top: 72px;
    margin-left: 3vw;
    svg {
      overflow: hidden;
    }
    background-image: none;
    width: 100%;
    height: 100%;
  }
  @include media-breakpoint-down(lg) {
    &.news {
      margin-left: 0px;
      margin-bottom: 30px;
    }
  }
  // @media screen and (min-width: 900px) {
  //   &.news {
  //     margin-left: 0px;
  //     margin-bottom: 83px;
  //   }
  // }
}
.help-support {
  .contents {
    position: absolute;
    width: 60%;
    height: 60%;
    h2 {
      left: -25px;
      position: relative;
    }
    h5 {
      left: -18px;
      position: relative;
    }
  }
  @include media-breakpoint-down(lg) {
    //margin-top: -80px;
    .contents {
      h2 {
        left: 35px;
        font-size: 32px;
      }
      h5 {
        left: 38px;
      }
    }
  }
  @include media-breakpoint-down(md) {
    background-color: $lightish-blue;
    &.nickX {
      background-color: $orange;
    }
    &.mtn {
      background-color: $mtnThemeMainColor;
    }
    margin-left: 0px;
    margin-top: 0px;
    svg {
      display: none;
    }
    .contents {
      position: relative;
      width: 100%;
      height: 100%;
      margin-left: 0px;
      padding: 20px 0px;
      h2 {
        font-size: 32px;
        left: -15px;
        position: relative;
      }
      h5 {
        left: -15px;
        position: relative;
      }
    }
    .mt-6 {
      margin-top: 2rem;
    }
    .mb-6 {
      margin-bottom: 2rem;
    }
  }
  @include media-breakpoint-down(sm) {
    .contents {
      h2 {
        position: relative;
      }
      h5 {
        position: relative;
      }
    }
  }
}
.load-bar {
  position: fixed;
  top: 0;
  width: 100%;
  height: 4px;
  z-index: 100000;
  background-color: lighten($secondary, 20%);
  
  
  .bar {
    content: "";
    display: inline;
    position: absolute;
    width: 0;
    height: 100%;
    left: 50%;
    text-align: center;
    &:nth-child(1) {
      animation: loading 2.1s linear 0.7s infinite;
      background-color: $secondary;
    }
    &:nth-child(2) {
      animation: loading 2.1s linear 1.4s infinite;
      background-color: lighten($secondary, 20%);
    }
  }
  &.orange{
    background-color: lighten($orange, 20%);
    .bar {
      &:nth-child(1) {
        background-color: $orange;
      }
      &:nth-child(2) {
        background-color: lighten($orange, 20%);
      }  
    }
  }
  &.mtnBlack{
    background-color: lighten($mtnBlack, 20%);
    .bar {
      &:nth-child(1) {
        background-color: $mtnBlack;
      }
      &:nth-child(2) {
        background-color: lighten($mtnBlack, 20%);
      }  
    }
  }
}

@keyframes loading {
  from {
    left: 50%;
    width: 0;
    z-index: 100;
  }
  33.3333% {
    left: 0;
    width: 100%;
    z-index: 10;
  }
  to {
    left: 0;
    width: 100%;
  }
}
.ui-blocker {
  background: transparent;
  width: 100%;
  height: 100%;
  z-index: 5000;
  position: fixed;
  top: 72px;
  left: 0px;
}

.view-all {
  font-weight: 500;
  font-size: 16px;
  color: $secondary;
  cursor: pointer;
  &:hover,
  &:focus {
    color: $secondary !important  ;
  }
  @include media-breakpoint-down(sm) {
    font-size: 12px;
  }
}
.view-all.mtn{
  color: $mtn-light-black;
  &:hover,
  &:focus {
    color: $mtn-light-black !important  ;
  }
}

.view-all-dashboard {
  font-weight: 500;
  font-size: 16px;
  color: $secondary;
  cursor: pointer;
  &:hover,
  &:focus {
    color: $secondary;
  }
  @include media-breakpoint-down(sm) {
    font-size: 12px;
  }
}
.swiper-container {
  width: 100%;
}
.swiper-slide {
  width: auto ;
  &:last-child {
    margin-right: 0px;
  }
}

.live-tournament,
.quick-play,
.premium-games,
.upcoming-tournament,
.popular-host,
.free-to-play-games,
.news,
.prize-pull,
.about-mtn-treasure,
.enter-raffles-wrapper,
.active-raffle {
  background-image: linear-gradient(to top, #ffffff, #f8faff);
}

.blank-space {
  height: 72px;
}
.mainContainer {
  margin-top: 72px;
}
.disable-scroll {
  overflow: hidden;
}
.btn-link {
  background: transparent;
}

.dynamic-banner {
  width: 100%;
  max-height: 500px;
  object-fit: cover;
}
.newBanner img{
  // min-height: 550px;
  background-image: linear-gradient(to top, #ffffff, #f8faff);

  @include media-breakpoint-down(xl) {
    min-height: 500px;
  }
  @include media-breakpoint-down(lg) {
    min-height: 316px;
  }
  @include media-breakpoint-down(md) {
    min-height: 253px;
  }
  @include media-breakpoint-down(sm) {
    min-height: 142px;
  }
  @include media-breakpoint-down(xs) {
    min-height: 85px;
  }
}
.no-gutters {
  &.no-space {
    margin-right: -15px;
    margin-left: -15px;
  }
}
.rounded-pill {
  border-radius: 50rem !important;
}
.overflow-auto {
  overflow: auto !important;
}
.loading{
  background: rgba(0, 0, 0, 0.1);
  // position: absolute;
  height:100vh;
  width: 100%;
  // top:72px;

}

.tournamet-info-wrapper {
  padding: 25px 0px;
  p {
    margin-bottom: 0px;
  }
  background: #ffffff;
  box-shadow: 0 2px 10px -3px rgba(69, 75, 84, 0.24);
  .responsive-adjust {
    .media {
      .timer {
        max-width: 32px;
        max-height: 32px;
      }
      .media-body {
        span {
          font-weight: 500;
        }
      }
    }
    .ms-flex {
      flex: 1;
      -ms-flex: 1;
    }
  }
  .counter-btn-wraper {
    span {
      font-weight: normal;
      font-size: 12px;
    }
  }
  .mt-negative-50 {
    margin-top: -50px;
  }
  .profile-avatar {
    max-width: 140px;
    width: 100%;
    box-shadow: 0 9px 20px -8px rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    @include media-breakpoint-down(md) {
      max-width: 65px;
    }
  }

  @include media-breakpoint-down(md) {
    .media {
      margin-top: 5px;
    }
    .countDown-timer {
      font-size: 11px;
    }
  }

  @include media-breakpoint-down(sm) {
    .title-small-responsive {
      font-size: 18px;
    }
    .countDown-timer {
      font-size: 11px;
    }
    padding: 12px 0px;
    .responsive-adjust {
      [class^="col"] {
        padding-left: 0px;
      }
      a {
        &.btn {
          &.ml-2 {
            margin-left: 0px !important;
          }
        }
      }
      h6 {
        font-size: 16px;
      }
      .media {
        margin-top: 10px;
        .timer {
          max-width: 24px;
          max-height: 24px;
        }
        .media-body {
          h6 {
            font-size: 12px;
          }
        }
      }
    }
  }

  &.host-detail {
    .social-media {
      img {
        width: 7vw;
        max-width: 28px;
        height: 7vw;
        max-height: 28px;
      }
    }
    @include media-breakpoint-down(md) {
      .host-heading {
        margin-top: -15px;
        margin-bottom: 20px;
        h1 {
          font-size: 21px;
        }
      }
      padding: 20px 0px;
    }
  }
}

.my-tournament-wrapper {
  margin-top: 72px;
  background-image: linear-gradient(to top, #ffffff, #f8faff);
  .card-wrapper {
    [class^="col-"] {
      padding-right: 10px;
    }

    .card {
      &.card-my-tournament {
        margin-bottom: 20px;
        @include popular-arcadex(
          $CardWidth: 212px,
          $cardHeight: 340px,
          $imgHeight: 170px,
          $card-border-radius: 18px
        );
        &.dark {
          @include box-shadow(0px, 3px, 10px, darken($info, 10%));
        }
        &.lock {
          .lock-info {
            img {
              width: 80px;
            }
            h6 {
              font-size: 12px;
            }
            h2 {
              font-size: 21px;
              margin-top: 0.7rem;
            }
            .btn-premium {
              &.unlock {
                width: 82%;
                line-height: 2.5;
              }
            }
          }
        }
        .card-body {
          padding: 0rem 2rem;
          @include media-breakpoint-down(sm) {
            padding: 0rem 1.2rem;
          }
          h4 {
            font-size: 18px;
          }
        }
        figure {
          margin-bottom: 7px;
          img {
            width: 56px;
            height: auto;
            margin-left: -10px;
          }
          figcaption {
            margin-top: 2px;
            color: $secondary;
            font-weight: 500;
          }
          margin-top: -30px;
        }
        @include media-breakpoint-down(sm) {
          @include popular-arcadex(
            $CardWidth: 200px,
            $cardHeight: 320px,
            $imgHeight: 176px,
            $card-inner-border-radius: 0px,
            $card-border-radius: 16px
          );
          .card-header {
            font-size: 12px;
          }
          .card-body {
            h4 {
              font-size: 16px;
              font-weight: bold;
            }
            figure {
              margin-top: -30px;
              figcaption {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
}