@import "../../../../styles/shared.scss";
.modalV1 {
  &.modal {
    .modal-footer {
      .prev-btn,
      .next-btn {
        &:focus,
        &:active {
          outline: none;
        }
      }
      .prev-btn {
        font-size: 16px;
        color: $bluish-grey;
        cursor: pointer;
        &.swiper-button-disabled {
          display: none;
        }
      }

      .next-btn {
        font-weight: bold;
        font-size: 16px;
        color: $secondary;
        cursor: pointer;
      }

      .onboarding-pagination {
        span {
          margin-left: 4px;
          margin-right: 4px;
          @include media-breakpoint-down(xs) {
            margin-left: 3px;
            margin-right: 3px;
          }
          &.swiper-pagination-bullet {
            background: #1b46e5;
          }
        }
      }
      &.mtn{
        .swiper-pagination-bullet{
          background: #ef5126;
        }
        .next-btn{
          color: #ef5126;
        }
        .onboarding-pagination {
          .swiper-pagination-bullet {
            background: #ef5126; 
          }
        }
      }
    }
    .popup-dismiss {
      position: absolute;
      right: 25px;
      cursor: pointer;
      top: 25px;
    }
    .swiper-slide {
      height: 92%;
    }
  }
}
.tutorial {
  &.swiper-slide {
    width: 100% !important;
  }
}
