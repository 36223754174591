/* The container <div> - needed to position the dropdown content */
.dropdown {
	position: relative;
	display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
	display: block;
	position: absolute;
	background-color: white;
	min-width: 160px;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	z-index: 999999;
	text-align: center;
	border-radius: 13px;
	box-shadow: 0 6px 26px -8px rgba(60, 64, 85, 0.16);
	border: 1px solid rgba(0, 0, 0, 0.08);
	padding-top: 5px;
	padding-bottom: 5px;
}

/* Links inside the dropdown */
.dropdown-content a,
.dropdown-content .a {
	color: black;
	padding: 0.25rem 1.5rem;
	text-decoration: none;
	display: block;
	font-size: 16px;
	font-weight: 500;
	// color: #ff0200;
}
.dropdown-content.nickx a,
.dropdown-content.nickx .a {
	color: #fc7323;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
	background-color: #f1f1f1;
}

/* Show the dropdown menu on hover */
.dropdown-content-hidden {
	display: none;
}

.dropdown-content-not-visible {
	visibility: hidden;
}
.App {
	padding-left: 100%;
}

.border-line {
	box-shadow: inset 0 -1px 0 0 #e0e4e8;
	height: 1px;
}

// .ink.animate {
//   -webkit-animation: ripple 0.5s linear;
//   -moz-animation: ripple 0.5s linear;
//   -ms-animation: ripple 0.5s linear;
//   -o-animation: ripple 0.5s linear;
//   animation: ripple 0.5s linear;
//   height: 24px; width: 24px; top: 1px; left: 6px;
// }
