@mixin card-arcadexMobi(
  $CardWidth: 288px,
  $cardHeight: 254px,
  $imgHeight: 254px,
  $card-inner-border-radius: 24px,
  $card-border-radius: 24px
) {
  width: $CardWidth;
  min-height: $cardHeight;
  background: #fff;
  border-radius: $card-inner-border-radius;
  box-shadow: 0 3px 16px -7px rgba(0, 0, 0, 0.2);
  .card-img {
    height: $imgHeight;
    border-radius: $card-inner-border-radius;
    object-fit: cover;
  }
  .card-body {
    border-bottom-left-radius: $card-border-radius;
    border-bottom-right-radius: $card-border-radius;
    background: transparent;
  }
  .card-img-overlay {
    .info {
      width: 100%;
      text-align: center;
      margin-bottom: 0px;
      padding-top: 12px;
      padding-bottom: 12px;
      font-size: 21px;
      line-height: 1.33;
      letter-spacing: normal;
      font-style: normal;
      font-weight: bold;
      border-bottom-left-radius: $card-border-radius;
      border-bottom-right-radius: $card-border-radius;
      background-image: linear-gradient(
        to bottom,
        rgba(255, 126, 152, 0.9),
        rgba(255, 53, 93, 0.9)
      );
      @include media-breakpoint-down(sm) {
        font-size: 16px;
        padding-top: 8px;
        padding-bottom: 8px;
        font-weight: 500;
      }
    }
  }
}
@mixin popular-arcadex(
  $CardWidth: 260px,
  $cardHeight: 410px,
  $imgHeight: 230px,
  $card-inner-border-radius: 0px,
  $card-border-radius: 18px
) {
  width: $CardWidth;
  height: $cardHeight;
  border-radius: $card-border-radius;
  box-shadow: 0 6px 26px -8px rgba(60, 64, 85, 0.16);
  background: transparent;
  .card-header {
    border-top-left-radius: $card-border-radius;
    border-top-right-radius: $card-border-radius;
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: $light-grey-blue;
    text-align: center;
    color: #fff;
    box-shadow: inset 0 -4px 0 0 rgba(0, 0, 0, 0.1);
    &.nickx {
      background-color: $lightish-orange;
    }
    &.mtn {
      background-color: $mtnBlack;
    }
  }

  .card-img {
    height: $imgHeight;
    border-radius: $card-inner-border-radius;
    object-fit: cover;
  }
  .card-body {
    border-bottom-left-radius: $card-border-radius;
    border-bottom-right-radius: $card-border-radius;
    background: #fff;
  }
  &.premium {
    .card-header {
      background-color: $premium;
    }
  }
  &.lock {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: calc(100% - 37px);
      top: 37px;
      width: 100%;
      background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.7),
        rgba(224, 228, 232, 0.7)
      );
      border-radius: $card-border-radius;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
      @include media-breakpoint-down(sm) {
        height: 89%;
        top: 11%;
      }
    }
    .card-img {
      opacity: 0.4;
      filter: grayscale(80%);
      object-fit: cover;
    }
    .card-body {
      opacity: 0.4;
    }
    .lock-info {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img {
        width: 100px;
        @include media-breakpoint-down(sm) {
          width: 70px;
        }
      }
    }
  }
  .lock-info {
    display: none;
  }
  &:hover {
    box-shadow: 0 6px 26px -10px rgba(60, 64, 85, 0.4);
  }
}
@mixin quick-play(
  $CardWidth: 260px,
  $cardHeight: 260px,
  $imgHeight: 230px,
  $card-inner-border-radius: 16px,
  $card-border-radius: 16px
) {
  width: $CardWidth;
  min-height: $cardHeight;
  background: #fff;
  border-radius: $card-inner-border-radius;
  .card-header {
    border-top-left-radius: $card-border-radius;
    border-top-right-radius: $card-border-radius;
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: $light-grey-blue;
    text-align: center;
    color: #fff;
    box-shadow: inset 0 -4px 0 0 rgba(0, 0, 0, 0.1);
    &.nickx {
      background-color: $lightish-orange;
    }
    &.mtn {
      background-color: $mtnBlack;
    }
  }
  .card-img {
    height: $imgHeight;
    border-bottom-left-radius: $card-border-radius;
    border-bottom-right-radius: $card-border-radius;
    box-shadow: 0 6px 26px -8px rgba(60, 64, 85, 0.16);
    object-fit: cover;
  }
}
@mixin popular-host($CardWidth: 100%, $cardHeight: 100%) {
  width: $CardWidth;
  min-height: $cardHeight;
  background: transparent;
}
@mixin free-to-play-game(
  $CardWidth: 288px,
  $cardHeight: 254px,
  $imgHeight: 254px,
  $card-border-radius: 24px
) {
  width: $CardWidth;
  min-height: $cardHeight;
  background: transparent;
  .card-img {
    height: $imgHeight;
    border-radius: $card-border-radius;
    box-shadow: 0 6px 26px -8px rgba(60, 64, 85, 0.16);
    object-fit: cover;
  }
}
@mixin prize-pool(
  $CardWidth: 260px,
  $cardHeight: 410px,
  $imgHeight: 230px,
  $card-inner-border-radius: 18px,
  $card-border-radius: 18px
) {
  width: $CardWidth;
  min-height: $cardHeight;

  border-radius: $card-border-radius;
  box-shadow: 0 6px 26px -8px rgba(60, 64, 85, 0.16);
  background: #fff;
  .card-header {
    border-top-left-radius: $card-border-radius;
    border-top-right-radius: $card-border-radius;
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: $lightish-orange;
    text-align: center;
    color: #fff;
    box-shadow: inset 0 -4px 0 0 rgba(0, 0, 0, 0.1);
  }

  .card-img {
    height: $imgHeight;
    border-radius: $card-inner-border-radius;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    object-fit: cover;
  }
  .card-body {
    border-bottom-left-radius: $card-border-radius;
    border-bottom-right-radius: $card-border-radius;
    background: transparent;
    &.first {
      border-top: 5px solid $sun-yellow;
    }
    &.second {
      border-top: 5px solid $light-grey-default;
    }
    &.third {
      border-top: 5px solid #e02d58;
    }
    &.fourth {
      border-top: 5px solid $secondary;
      &.mtn {
        border-top: 5px solid #464646;
      }
    }
    
  }
  &:hover {
    box-shadow: 0 6px 26px -10px rgba(60, 64, 85, 0.4);
  }
}
@mixin enter-raffles(
  $CardWidth: 210px,
  $cardHeight: 100%,
  $imgHeight: 314px,
  $card-border-radius: 24px
) {
  width: $CardWidth;
  height: $cardHeight;
  background: transparent;
  .card-info {
    border-bottom-left-radius: $card-border-radius;
    border-bottom-right-radius: $card-border-radius;
  }
  .card-img {
    height: $imgHeight;
    border-radius: $card-border-radius;
    object-fit: cover;
  }
  .ovarlay-background {
    border-bottom-left-radius: $card-border-radius;
    border-bottom-right-radius: $card-border-radius;
  }
}
@mixin card-my-team(
  $CardWidth: 220px,
  $cardHeight: 360px,
  $card-border-radius: 24px
) {
  width: $CardWidth;
  height: $cardHeight;
  background: #fff;
  border-radius: $card-border-radius;
  overflow: hidden;
  .card-body {
    padding: 0px;
  }
}
