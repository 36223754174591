@import "../../../styles/shared.scss";
.Arcadex-play {
      .bannerImg {
          img {
              max-height: 300px;
        }
      }
      .modal-body {
          padding: 0px !important;
      }
      .fpb-icon {
        width: 60px;
        height: 60px;
        position: fixed;
        bottom: 30px;
        left: 30px;
        background-image: url(/static/media/fpb.369177b2.png);
      }
      .card-body {
          
          .player-info {
              .avatar {
                  border-radius: 50%;
                  box-shadow: 0 6px 26px -8px rgba(60, 64, 85, 0.30);
                  border:2px solid #fff;
                  margin-top: -40px;
                  img {
                      width: 80px;
                        height: 80px;
                        border-radius: 50%;
                        object-fit: cover;
                       
                        
                  }
              }
             
          }
          .gift-img {
              img {
                  max-width: 100px;
              }
          }
          .cancel-btn {
              font-size: 16px;
          }

         
      }
  }
  .mobile-scren {
      .bannerImg {
          img {
              max-height: 144px;
              object-fit: cover;
              object-position: top

              
        }
      }
     
      .card-body {
          padding: 0px;
          .player-info {
              background: #fff;
              box-shadow: 0 2px 10px -3px rgba(69, 75, 84, 0.24);
              padding: 10px 15px;
          }
          .gift-wrapper {
              padding: 0px 30px;
          }
      }
      @include media-breakpoint-up(sm) { 
          .bannerImg {
              img {
                  max-height: 270px;
              }
          } 
      }
  }

