@import "../../../styles/shared.scss";
.modal {
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.75);

  @media (min-width: 992px) {
    .modal-dialog {
      max-width: 650px;
    }
  }
}

.modal-multiple-tabs-enforced {
  color: #3c4154;
  background-color: white;
}

