@import "../../../styles/shared.scss";

  .score-card {

      .modal-body {
          padding: 0 15px !important;
      }
      .card-body {
          

          .player-info {
              .avatar {
                  border-radius: 50%;
                  box-shadow: 0 6px 26px -8px rgba(60, 64, 85, 0.30);
                  border:2px solid #fff;
                  margin-top: -40px;
                  img {
                      width: 80px;
                        height: 80px;
                        border-radius: 50%;
                        object-fit: cover;
                       
                        
                  }
              }
             
          }
          .gift-img {
              img {
                  max-width: 200px;
              }
              @include media-breakpoint-down(md) {
                  img {
                  max-width: 150px;
              }
              }
          }
          .gold-token {
              img {
                  max-width: 60px;
              }
              .earned-token {
                  color: #7b8997;
              }
              &.br-1 {
                  border-right: 1px solid #e9ecef;
              }
          }
          .cancel-btn {
              font-size: 16px;
          }

         
      }
  }
.popup-dismiss{
    z-index: 999;
}

