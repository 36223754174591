@import "../../../styles/shared.scss";
.modalV1 {
  &.modal {
    &.modal-unfollow {
      .modal-footer {
        padding: 0 !important;
        @include media-breakpoint-down(md) {
          height: auto !important;
        }
      }
      .modal-dialog {
        @include media-breakpoint-up(md) {
          max-width: 332px;
        }
      }
    }
  }
}
