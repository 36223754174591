@import "../../../../styles/shared.scss";
.modalV1{
  .login-form {
    .forgot-password {
      padding-left: 0px;
      font-weight: normal;
      font-size: 12px;
      color: $secondary;
    }
    .btn-outline-secondary {
      @include button-theme();
      border: 2px solid $lightish-blue;
      color: $lightish-blue;
      text-shadow: none;
      font-weight: 500;
      font-size: 15px;
      background:#fff;
      &.social-login {
        @include button-size(
          $padding-y: auto,
          $padding-x: auto,
          $font-size: 16px,
          $line-height: 25px,
          $border-radius: 100px
        );
        .social-icon {
          &.facebook {
            img {
              width: 0.7rem;
            }
          }
          &.google {
            img {
              width: 1.3rem;
            }
          }
        }
      }
      &:hover {
        background: lighten($secondary, $amount: 45);
      }
    }
    .without-lable {
      .field-icon.eye-open {
        background-image: url("../../../../assets/images/svg/eye-default.svg");
      }
      .field-icon.eye-close {
        background-image: url("../../../../assets/images/svg/eye-close.svg");
      }
      .field-icon {
        float: right;
        margin-left: -25px;
        margin-top: -25px;
        position: relative;
        z-index: 99999999999;
        width: 25px;
        height: 25px;
      }
    }
  }
}
